import Ajax from './http';

//获取产品列表
export function getProductListApi(values) {
	return Ajax.post('/product/list',values);
}
//获取产品汇率
export function getProductRateApi(values) {
	return Ajax.post('/payment/current_exchange_rate', values);
}
//提交订单
export function submitOrderApi(values) {
	return Ajax.post('/payment/gen_order',values);
}
//查看订单状态
export function getOrderStatusApi(values) {
	return Ajax.post('/payment/get_order_status',values);
}