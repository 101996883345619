<template>
  <div class="recharge">
    <h1>VIP高速套餐</h1>
    <p class="desc">下载Transgle，立即体验回国加速，帮你访问和加速爱奇艺、哔哩哔哩、腾讯视频、QQ音乐和网易云音乐等国内服务，不管身在何处，距离不再遥远</p>
    <div class="vip_box">
      <div class="box">
        <img src="../assets/home_ic_line.png" alt />
        <span>精品路线</span>
      </div>
      <div class="box">
        <img src="../assets/home_ic_hd.png" alt />
        <span>高清播放</span>
      </div>
      <div class="box">
        <img src="../assets/home_ic_service.png" alt />
        <span>客服优先</span>
      </div>
      <div class="box">
        <img src="../assets/home_ic_discounts.png" alt />
        <span>物超所值</span>
      </div>
    </div>
    <div class="product_box" v-if="productLists.length>0">
      <div
        :class="[{'box_active':mealsIndex==index},'box']"
        @click="choseMeals(index)"
        v-for="(item,index) in productLists"
        :key="item.order_id"
        :style="{marginRight:(index+1)%2==0?0:'4%'}"
      >
        <h4 class="title">{{item.title}}</h4>
        <p class="price_wrap" v-if="item.id==13">
          <span class="price price_red">{{item.price/100}}</span>
          <span class="currency currency_red">{{item.currency}}</span>
<!--          <span class="days">/{{item.days}}天</span>-->
<!--          <span class="year">(年付)</span>-->
        </p>
        <p class="price_wrap" v-else-if="item.id==12">
          <span class="price">{{item.price/100}}</span>
          <span class="currency">{{item.currency}}</span>
          <!-- <span class="line_through_price ">原价 349AUD</span> -->
          <!--          <span class="days">/{{item.days}}天</span>-->
          <!--          <span class="year">(年付)</span>-->
        </p>
        <p class="price_wrap" v-else>
          <span class="price">{{item.price/100}}</span>
          <span class="currency">{{item.currency}}</span>
          <!--          <span class="days">/{{item.days}}天</span>-->
          <!--          <span class="year">(年付)</span>-->
        </p>
        <p>
<!--          类型-->
          <span class="type">{{item.type_text}}</span>
        </p>
        <p>
          区域
          <span class="region">{{item.region.region}}</span>
        </p>
        <p class="device">{{item.device_limit === 0 ? '同时上线多台设备' : '同时上线' + item.device_limit + '台设备'}}</p>
        <p class="device">{{item.description.split("|")[0]}}</p>
        <p class="device">{{item.description.split("|")[1]}}</p>
        <span v-if="item.is_recommend==1" class="intro_logo">推荐</span>
      </div>
    </div>
    <div class="product_box" v-if="dayList.length>0 && choseId==13">
      <div
          :class="[{'box_active':dayIndex==index},'box','day_box']"
          @click="choseDays(index)"
          v-for="(item,index) in dayList"
          :key="item.order_id"
          :style="{marginRight:(index+1)%2==0?0:'4%'}"
      >
        <p class="chose_day">设备押金</p>
        <p class="chose_price">
          <!--          <span class="cost_price" v-if='item.real_price!==item.cost_price'>${{item.cost_price/100}}</span>-->
          <span class="cost_price_min" v-if='item.real_price!==item.cost_price'>30天到期后退还</span>
          <span class="cost_price" v-else></span>
          <span class="real_price">${{item.real_price/100}}</span>
        </p>
      </div>
    </div>
    <div class="product_box" v-if="dayList.length>0 && choseId!=13">
      <div
        :class="[{'box_active':dayIndex==index},'box','day_box']"
        @click="choseDays(index)"
        v-for="(item,index) in dayList"
        :key="item.order_id"
        :style="{marginRight:(index+1)%2==0?0:'4%'}"
      >
        <p class="chose_day">{{item.days}}天</p>
        <p class="chose_price">
<!--          <span class="cost_price" v-if='item.real_price!==item.cost_price'>${{item.cost_price/100}}</span>-->
          <span class="cost_price" v-if='item.real_price!==item.cost_price'>${{item.discounts_off}}</span>
          <span class="cost_price" v-else></span>
          <span class="real_price">${{item.real_price/100}}</span>
        </p>
      </div>
    </div>
<!--    <div class="pay_notice">-->
<!--      * 首次购买设备或含设备的套餐时，需额外支付168.99AUD的设备采购费及15AUD的邮费。-->
<!--    </div>-->
    <div class="buy_ways">
      <h1 class="title">选择支付方式</h1>
      <div>
        <button
          :class="[{'buy_btn_active':payIndex==index},'buy_btn']"
          v-for="(value,index) in payWays"
          :key="index"
          @click="payChose(index)"
        >
          <img v-if="index==0" class="royal" src="../assets/royalpay.png" alt />
          <img v-if="index==1" class="paypal" src="../assets/paypal2.png" alt />
          <span v-if="index==2">
            <img class="wallet" src="../assets/transfer2.png" alt />
            银行转账
          </span>
          <span v-if="index==3">
            <img class="wallet" src="../assets/wallet.png" alt />
            钱宝支付
          </span>
        </button>
        <van-radio-group v-model="channel" direction="horizontal" v-if="payIndex<1">
          <van-radio name="Wechat">微信支付</van-radio>
          <van-radio name="Alipay">支付宝支付</van-radio>
        </van-radio-group>
      </div>
      <div class="buy_result" v-if="chosedList">
        <p v-if="channel=='Wechat'&&payIndex<1">
          <span>微信汇率</span>
          <span>{{wechat_rate}}</span>
        </p>
        <p v-if="channel=='Alipay'&&payIndex<1">
          <span>支付宝汇率</span>
          <span>{{alipay_rate}}</span>
        </p>

        <p v-if="payIndex==2">Account Name: Mesup Pty Ltd</p>
        <p v-if="payIndex==2">BSB: 063019</p>
        <p v-if="payIndex==2">Account No.: 12180023</p>
        <p v-if="payIndex==2">Ref: Phone number & Name</p>

        <p>
          <span>{{chosedList.title}}</span>
          <span>{{dayList[dayIndex].days}}天</span>
        </p>
        <p>
          <span>账户使用费</span>
          <span>{{dayList[dayIndex].real_price/100}} {{dayList[dayIndex].currency}}</span>
        </p>
<!--        <p v-if="productLists[mealsIndex].type === 2 || productLists[mealsIndex].type === 3 || productLists[mealsIndex].type === 6">-->
<!--          <span>* 设备一次性购买</span>-->
<!--          <span>168.99 {{dayList[dayIndex].currency}} </span>-->
<!--        </p>-->
<!--        <p v-if="productLists[mealsIndex].type === 2 || productLists[mealsIndex].type === 3 || productLists[mealsIndex].type === 6">-->
<!--          <span>* 邮费</span>-->
<!--          <span>15 {{dayList[dayIndex].currency}} </span>-->
<!--        </p>-->
      </div>
      <button class="buy_now" v-if="payIndex!=2" @click="submit">立即购买</button>
      <p style="color:#ff6854" v-if="payIndex==2" @click="submit">* 支付成功后请联系客服确认</p>
    </div>
    <van-overlay :show="isShow" @click="closeMask">
      <div class="wrapper">
        <div class="block1" v-if="!status">
          <div class="qr_inner_box">
            <h1>{{channel=='Wechat'?'微信支付':'支付宝支付'}}</h1>
            <div id="qrcode"></div>
          </div>
        </div>

        <div class="block" v-else>
          <div class="inner_box">
            <i
              style="font-size:50px"
              :class="{'el-icon-circle-check':status==1,'el-icon-circle-close':status==2||status==3}"
            ></i>
            <p style="font-size:20px;margin-top:20px;">{{statusStr[status]}}</p>
            <div v-if="status==1">
              <router-link style="width:40%" to="/myorder">
                <button class="have_look">查看订单</button>
              </router-link>
              <button class="hang_out" @click="closeMask">继续逛逛</button>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import QRcode from "qrcodejs2";
import {
  getProductListApi,
  getProductRateApi,
  submitOrderApi,
  getOrderStatusApi
} from "api/Recharge";
export default {
  computed: {
    chosedList: function() {
      return this.productLists[this.mealsIndex];
    }
  },

  data() {
    return {
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      productLists: [],
      dayList: [],
      payWays: [
        { value: "RoyalPay" },
        // { value: "Paylinx" },
        { value: "Paypal" },
        { value: "Transfer" },
        { value: "Credit" }],
      mealsIndex: 0, //当前选择的套餐序列
      choseId:0, // 当前选择套餐ID
      dayIndex: 0, //当前选择的天数
      payIndex: 0, //当前选择的支付序列  0：royalpay,1:钱包
      channel: "Wechat", //royalpay默认选择微信支付
      rate:{
        RoyalPay:{
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        },
        Paylinx:{
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        }
      },
      alipay_rate: "", //支付宝汇率
      wechat_rate: "", //微信汇率
      isShow: false, //支付二维码弹窗
      status: "", //订单状态
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单超时未支付"
      },
      timer: null
    };
  },
  methods: {
    //选择套餐
    choseMeals(index) {
      this.mealsIndex = index;
      this.choseId = this.productLists[index].id;
      this.dayList = this.productLists[index].product_price;
      this.dayIndex = 0;
    },
    //选择天数
    choseDays(index) {
      this.dayIndex = index;
    },
    //选择支付方式 ---->  钱包/royalpay
    payChose(index) {
      this.payIndex = index;
      this.changeRate(index)
    },
    changeRate(index){
      if (this.payWays[index].value == 'RoyalPay') {
        this.wechat_rate = this.rate.RoyalPay.wechat_rate
        this.alipay_rate = this.rate.RoyalPay.alipay_rate
      }
       if (this.payWays[index].value == 'Paylinx') {
        this.wechat_rate = this.rate.Paylinx.wechat_rate
        this.alipay_rate = this.rate.Paylinx.alipay_rate
      }
    },
    //提交订单
    submit() {
      const loading = this.$loading({
        lock: true,
        text: "提交订单中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const params = {
        payment_type: this.payWays[this.payIndex].value,
        product_id: this.productLists[this.mealsIndex].id,
        channel: this.channel,
        days: this.dayList[this.dayIndex].days,
        mode: 'mobile'
      };
      let winOpen = window.open("", "_blank");

      submitOrderApi(params)
        .then(res => {
          if (res.code == 0) {
            if (params.payment_type == 'Paypal') {
              setTimeout(() => {
                winOpen.location = res.data.code_url;
              }, 100);
                // window.open(res.data.code_url, '_blank');
            } else {
              this.isShow = true;
              window.open(res.data.code_url);
              document.getElementById('qrcode').innerHTML="";
              setTimeout(() => {
                let shareUrl = res.data.code_url;
                let qrcode = new QRcode("qrcode", {
                  text: shareUrl,
                  width: 200, //图像宽度
                  height: 200, //图像高度
                  colorDark: "#517cfc",
                  colorLight: "#ffffff",
                  correctLevel: QRcode.CorrectLevel.H
                });
                qrcode.clear(); //清除二维码
                qrcode.makeCode(shareUrl); //生成另一个新的二维码
                this.timer = setInterval(
                  () => this.getResult(res.data.order_id),
                  5000
                );
              }, 1000);
            }
          }
        })
        .finally(() => {
          loading.close();
          this.isLoading = false;
        });
    },
    //查看支付订单状态
    getResult(order_id) {
      if (this.isShow) {
        getOrderStatusApi({ order_id }).then(res => {
          if (res.code == 0) {
            const { status } = res.data;
            //订单状态:0待支付/1支付成功/2支付失败/3订单关闭
            if (status != 0) {
              this.status = status;
              clearInterval(this.timer);
            }
          } else {
            clearInterval(this.timer);
            this.isShow = false;
          }
        });
      }
    },
    //关闭弹窗
    closeMask() {
      this.isShow = false;
      this.status = 0;
      clearInterval(this.timer);
    }
  },
  mounted() {
    //获取列表
    getProductListApi().then(res => {
      if (res.code == 0) {
        this.productLists = res.data;
        const index = this.productLists.findIndex(
          item => item.is_recommend == 1
        );
        this.mealsIndex = index;
        this.choseId = res.data[index].id;
        this.dayList = res.data[index].product_price;
      }
    });
    //获取汇率
    getProductRateApi({payment_type:"RoyalPay"}).then(res => {
      if (res.code == 0) {
        if (res.data.RoyalPay != undefined) {
          this.rate.RoyalPay.wechat_rate = res.data.RoyalPay.wechat_rate
          this.rate.RoyalPay.alipay_rate = res.data.RoyalPay.alipay_rate
        }

        if (res.data.Paylinx != undefined) {
          this.rate.Paylinx.wechat_rate = res.data.Paylinx.wechat_rate
          this.rate.Paylinx.alipay_rate = res.data.Paylinx.alipay_rate
        }

        this.changeRate(0)
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.recharge {
  padding: 0 16px;
  h1 {
    font-size: 26px;
    color: #202020;
    margin: 30px 0;
    font-weight: 500;
    text-align: center;
  }
  .desc {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 28px;
  }
  .vip_box {
    @include flex_between;
    .box {
      margin-top: 20px;
      width: 20%;
      text-align: center;
      img {
        width: 100%;
        padding-bottom: 10px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #202020;
        line-height: 20px;
      }
    }
  }
  .product_box {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    border-bottom: 1px dashed #bcc5d4;
    .box_active {
      border: 2px solid #517cfc;
    }
    .box {
      flex-direction: column;
      width: 48%;
      text-align: center;
      margin-top: 45px;
      margin-right: 4%;
      box-sizing: border-box;
      padding: 20px 10px 5px 10px;
      background: #edf9ff;
      border-radius: 4px;
      position: relative;
      .title {
        font-size: 18px;
        font-weight: 500;
        color: #202020;
        line-height: 25px;
      }
      p {
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .currency,
      .limit,
      .price,
      .type,
      .region {
        color: #517cfc;
      }
      .price_wrap {
        font-size: 12px;
        .price {
          font-size: 20px;
          font-weight: 700;
        }
        .price_red {
          color: #ff6854;
        }
        .currency {
          font-size: 12px;
        }
        .currency_red {
          color: #ff6854;
        }
        .line_through_price {
          font-size: 12px;
          color: #517cfc;
          text-decoration: line-through;
          text-decoration-color: #ff6854;
          display: block;
        }
      }
      .intro_logo {
        display: inline-block;
        padding: 3px 5px;
        color: #fff;
        font-size: 16px;
        position: absolute;
        right: 0;
        top: 0;
        border-bottom-left-radius: 5px;
        background: #517cfc;
      }
      .chose_day {
        font-size: 16px;
        color: #202020;
        text-align: left;
        font-weight: bold;
      }
      .chose_price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cost_price {
          font-size: 16px;
          font-weight: bold;
          color: #ff6854;
          //text-decoration: line-through;
        }
        .cost_price_min {
          font-size: 12px;
          font-weight: bold;
          color: #ff6854;
          //text-decoration: line-through;
        }
        .real_price {
          font-size: 28px;
          color: #517cfc;
        }
      }
    }
    .day_box {
      background: transparent;
      box-shadow: 0px 2px 4px 0px rgba(134, 134, 162, 0.11);
    }
  }
  .pay_notice {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 16px;
    color: #8c939d;
    margin-top: 30px;

  }
  .buy_ways {
    .title {
      text-align: left;
    }
    .buy_btn {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #bcc5d4;
      font-weight: 500;
      color: #202020;
      margin: 20px 0;
      background: #fff;
      text-align: center;
      justify-content: center;
      font-size: 16px;
      .royal {
        width: 100px;
        height: 24px;
      }
      .paylinx{
        width: 110px;
        height: 30px;
      }
      .paypal{
        width: 100px;
        height: 24px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .wallet {
        height: 28px;
        margin-right: 10px;
      }
    }
    .buy_btn_active {
      border: 1px solid #517cfc;
    }
    .van-radio-group--horizontal {
      justify-content: around;
      margin-bottom: 18px;
    }
    .buy_result {
      p {
        @include flex_between;
        margin-bottom: 10px;
        font-weight: 500;
        span {
          font-size: 16px;
          color: #7e7e7e;
        }
      }
    }
    .buy_now {
      margin: 30px auto;
      width: 100%;
      height: 48px;
      background: #517cfc;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block1 {
      width: 85%;
      height: 300px;
      background-color: #fff;
      .qr_inner_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-weight: 600;
          font-size: 28px;
          margin: 20px 0;
        }
      }
    }
    .block {
      width: 85%;
      height: 300px;
      background-color: #fff;

      .inner_box {
        padding: 40px 0;
        text-align: center;
        padding-top: 60px;
        .el-icon-circle-check {
          color: green;
        }
        button {
          height: 40px;
          line-height: 40px;
          color: #fff;
          background: #517cfc;
          font-size: 16px;
          text-align: center;
          width: 40%;
        }
        .hang_out {
          margin-left: 10%;
          margin-top: 60px;
        }
      }
    }
  }
}
.transfer{
    p {
      font-size: 16px;
      font-weight: 400;
      color: #4a4a4a;
      line-height: 28px;
      text-align: justify;
    }
  }
</style>